/* Block: .sign-up */
.sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
}

/* Block: .sign-up__container */
.sign-up__container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  animation: fadeIn 0.3s ease-in-out;
}

/* Element: .sign-up__title */
.sign-up__title {
  margin-bottom: 15px;
  text-align: center;
  font-size: 30px;
  color: #333;
}

.sign-up__label {
  font-weight: 500;
}

/* Element: .sign-up__form */
.sign-up__form {
  display: flex;
  flex-direction: column;
}

/* Element: .sign-up__input */
.sign-up__input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

/* Element: .sign-up__button */
.sign-up__primary-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.sign-up__primary-button:hover {
  background-color: #0056b3;
}

.sign-up__secondary-button--disabled {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
  background-color: white;
  border: none;
  padding: 0;
  font-weight: 500;
}

.sign-up__secondary-button--disabled:hover {
  color: #0056b3;
  cursor: not-allowed;
}

.sign-up__secondary-button {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
  background-color: white;
  border: none;
  padding: 0;
  font-weight: 500;
  margin-top: 15px;
  text-align: right;
  margin-bottom: 5px;
}

.sign-up__secondary-button:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Element: .sign-up__error */
.sign-up__error {
  color: red;
  margin-top: 10px;
}

/* Element: .sign-up__link */
.sign-up__link {
  margin-top: 10px;
}

/* Element: .sign-up__link-text */
.sign-up__link-text {
  color: #007bff;
  text-decoration: none;
}

.sign-up__link-text:hover {
  text-decoration: underline;
}

/* Block: .password-hint */
.password-hint {
  font-size: 0.9em;
  color: #666;
}

/* Element: .password-hint__title */
.password-hint__title {
  margin-bottom: 3px;
}

/* Element: .password-hint__list */
.password-hint__list {
  padding-left: 15px;
}

/* Element: .password-hint__item */
.password-hint__item {
  display: flex;
  align-items: center;
}

/* Modifier: .password-hint__item--valid */
.password-hint__item--valid .password-hint__text {
  color: green;
  font-weight: bold;
}

/* Element: .password-hint__check */
.password-hint__check {
  margin-right: 5px;
  color: green;
  font-weight: 700;
  margin-left: -2px;
}

/* Element: .password-hint__bullet */
.password-hint__bullet {
  margin-right: 10px;
  color: #666;
  font-weight: 700;
}

/* Element: .password-hint__text */
.password-hint__text {
  font-size: 1em;
}

@media (max-width: 768px) {
  .password-hint {
    font-size: 0.8em;
    color: #666;
  }

  .password-hint__title {
    margin-bottom: 3px;
  }
}
/* Animation for container */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
