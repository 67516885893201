.level-progress-card {
  padding: 24px;
  max-width: 1160px;
  min-width: 320px;
  width: 100%;
  margin: 0;
  border-radius: 8px;
  border: 1px solid var(--light-grey-color);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.level-progress-card h3 {
  font-size: 1.6rem;
}

.progress-bar-container {
  background-color: var(--lighter-grey-color);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
}

.progress-bar {
  background-color: var(--green-color);
  padding: 8px;
  color: white;
  text-align: center;
  border-radius: 4px;
  white-space: nowrap;
}

.progress-bar-label {
  margin-top: -15px;
  text-align: right;
  color: var(--green-color);
  font-weight: bold;
}

.radicals-section .hanzi-section {
  margin-bottom: 16px;
}

.radicals-container,
.hanzi-container {
  display: flex;
  flex-wrap: wrap;
}

.level-progress__items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.level-progress__item {
  padding: 8px;
  width: 50px;
  height: 80px;
  max-width: 60px;
}

.level-progress__radical-item-symbol {
  border-radius: 4px;
  background-color: var(--blue-color);
  color: white;
  font-weight: bold;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
}

.level-progress__hanzi-item-symbol {
  border-radius: 4px;
  background-color: var(--purple-color);
  color: white;
  font-weight: bold;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
}

.level-progress__item-progress-container {
  width: 50px;
  margin-top: 8px;
}

.level-progress__item-progress {
  background-color: var(--lighter-grey-color);
  border-radius: 3px;
  height: 6px;
  overflow: hidden;
  position: relative;
}

.level-progress__item-progress-bar {
  height: 100%;
  background-color: var(--green-color);
  transition: width 0.3s;
}

@media (max-width: 768px) {
  .level-progress__item {
    width: calc(20% - 10px); /* 4 items per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .level-progress__item {
    width: calc(25% - 10px); /* 4 items per row on very small screens */
  }
}
