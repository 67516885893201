.item-detail-page {
  padding: 30px 20px 30px 20px;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 60px;
}

.item-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  position: relative;
  flex-wrap: wrap;
}

.item-icon-box {
  background-color: #0096ff;
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  transition:
    transform 0.4s ease,
    box-shadow 0.3s ease;
  display: inline-block;
  transform-origin: top left;
  z-index: 2;
}

.item-icon-box:hover {
  transform: scale(2);
  background-color: #4db4f8;
  cursor: pointer;
  z-index: 3;
}

.item-name {
  margin-left: 20px;
  transition: margin-left 0.4s ease;
  flex-grow: 1;
  z-index: 1;
}

.item-header .item-icon-box:hover ~ .item-name {
  margin-left: 130px;
}

.item-mnemonics {
  margin-top: 30px;
  font-size: 18px;
  color: #333;
  width: 100%;
  transition: transform 0.3s ease;
  transform: translateY(0);
  z-index: 1;
}

.item-icon-box:hover ~ .item-mnemonics {
  transform: translateY(100px);
}
