/* AboveFold.css */
.aboveFold__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  margin-top: 60px;
  background-image: url('./images/QilinAFBackgroundImage.png');
}

.aboveFold__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 160px;
  text-align: left;
}

.aboveFold__text {
  margin: 10px 0;
}

.aboveFold__text-Qilin {
  text-align: right;
  margin-top: 30px;
}

.aboveFold__button-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.aboveFold__button {
  margin: 40px 30px 0 0;
  padding: 15px 30px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}
.aboveFold__text-container {
  margin-top: 80px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .aboveFold__text {
    font-size: 1.8em;
  }

  .aboveFold__overlay {
    padding-left: 35px;
  }

  .aboveFold__text-container {
    margin-top: 180px;
  }

  .aboveFold__button-container {
    flex-direction: column;
    width: 100%;
  }

  .aboveFold__button {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .aboveFold__image-container {
    height: 80vh;
    background-image: url('./images/QilinAFBackgroundImageMobile.png');
  }

  .aboveFold__text {
    font-size: 1.2em;
  }

  .aboveFold__button {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 1em;
  }
}
