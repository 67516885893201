.subscription {
    text-align: center;
    margin: 20px;
  }
  
  .subscription__title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .subscription__description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #666;
  }
  
  .subscription__notice {
    background-color: #0275d8;
    color: white;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .subscription__notice p {
    margin: 10px 0;
  }
  
  .subscription__plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 25px;
  }
  
  .subscription__plan {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    width: 280px;
    margin: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .subscription__plan h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .subscription__plan p {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #666;
  }
  
  .subscription__button {
    background-color: #0096ff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .subscription__button:hover {
    background-color: #4db4f8;
  }
  