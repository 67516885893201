/* HeaderLoggedIn.css */
.header {
  background-color: var(--white-color);
  color: var(--text-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1160px;
  margin: 0 auto;
}

.header__logo {
  display: flex;
  padding: 10px 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  color: var(--orange-color);
}

.header__logo:hover {
  cursor: pointer;
}

.header__logo-image {
  height: 35px;
  width: 35px;
  margin-right: 2px;
}

.header__nav {
  display: flex;
  gap: 0px;
  margin-left: auto;
}

.header__button {
  font-size: medium;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 20px;
  background: none;
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  overflow: hidden;
}

.button-text,
.button-text-hover {
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.button-text-hover {
  opacity: 0;
  position: absolute;
  transform: translateY(10px);
}

.header__button:hover .button-text {
  opacity: 0;
  transform: translateY(-10px);
}

.header__button:hover .button-text-hover {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .header__button {
    display: none;
  }
}
