/* Level.css */

.level {
  padding: 30px 20px 30px 20px;
  max-width: 1200px;
  margin: 0 auto;
  /* background-color: #f5f5f5; */
  border-radius: 10px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  margin-top: 60px;
}

.level__title {
  font-size: 36px;
  margin-bottom: 30px;
  color: #004e98;
  text-align: left;
}

.level__section {
  margin-bottom: 40px;
}

.level__section h2 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #004e98;
  padding-bottom: 5px;
}

.level__items {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.level__item {
  width: calc(16.6% - 12px); /* Adjust based on the number of items per row */
  min-width: 120px;
  height: 120px;
  background-color: #0096ff;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  text-align: center;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease;
}

.level__item:hover {
  transform: scale(1.08);
  background-color: #4db4f8;
  cursor: pointer;
}

.level__item-symbol {
  font-size: 48px;
  font-weight: bold;
}

.level__item-meaning {
  font-size: 14px;
  margin-top: 5px;
}

.level__item-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  padding: 3px;
  border-radius: 50%;
  font-size: 12px;
}

@media (max-width: 768px) {
  .level__item {
    width: calc(33.33% - 10px); /* 3 items per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .level__item {
    width: calc(50% - 10px); /* 2 items per row on very small screens */
  }
}
