/* ReviewForecastCard.css */
.review-forecast-card {
  padding: 20px;
  max-width: 1160px;
  min-width: 320px;
  width: 100%;
  margin: 0;
  border-radius: 8px;
  border: 1px solid var(--light-grey-color);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-forecast-card__title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.review-forecast-card__description {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #666;
}

.review-forecast-card__image {
  width: 300px;
  height: 300px;
  align-self: center;
  /* background-image: url('./assets/lessons.png'); */
  background-size: contain;
  background-repeat: no-repeat;
}
