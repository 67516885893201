/* Block: .sign-in */
.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
}

/* Block: .sign-in__container */
.sign-in__container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  animation: fadeIn 0.3s ease-in-out;
}

/* Element: .sign-in__title */
.sign-in__title {
  margin-bottom: 15px;
  text-align: center;
  font-size: 30px;
  color: #333;
}

.sign-in__label {
  font-weight: 500;
}

/* Element: .sign-in__form */
.sign-in__form {
  display: flex;
  flex-direction: column;
}

/* Element: .sign-in__input */
.sign-in__input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.sign-in__input:focus {
  border-color: #007bff;
  outline: none;
}

/* Element: .sign-in__button */
.sign-in__primary-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.sign-in__primary-button:hover {
  background-color: #0056b3;
}

/* Element: .sign-in__error */
.sign-in__error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

/* Element: .sign-in__link */
.sign-in__link {
  margin-top: 15px;
  text-align: center;
}

/* Element: .sign-in__link-text */
.sign-in__link-text {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
  font-weight: 500;
}

.sign-in__link-text:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Element: .sign-in__forgot-password-text */
.sign-in__secondary-button {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
  background-color: white;
  border: none;
  padding: 0;
  font-weight: 500;
}

.sign-in__secondary-button:hover {
  color: #0056b3;
  text-decoration: underline;
}

.sign-in__secondary-button--disabled {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
  background-color: white;
  border: none;
  padding: 0;
  font-weight: 500;
}

.sign-in__secondary-button--disabled:hover {
  color: #0056b3;
  cursor: not-allowed;
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .sign-in__container {
    padding: 15px;
    width: 100%;
  }
}

/* Animation for container */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
