.compose-lesson {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #92c0f5;
  padding: 20px;
  box-sizing: border-box;
}

.compose-lesson__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 20px;
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 450px;
  min-height: 500px; /* Ensure a minimum height for consistent layout */
}

.compose-lesson__title {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
  font-weight: bold;
}

.lesson-element {
  margin-bottom: 20px;
  width: 100%;
}

.lesson-element__symbol {
  font-size: 68px;
  font-weight: bold;
  color: var(--blue-color);
  margin-bottom: 10px;
  position: relative;
  /* background-image: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.1)), url('./images/grid_image.png'); */
  background-size: cover;
  display: inline-block;
  text-align: center;
  width: 100px; /* Adjust this to match the size of your grid */
  height: 100px; /* Adjust this to match the size of your grid */
}

.lesson-element__meaning {
  font-size: 18px;
  color: #666666;
  margin-bottom: 15px;
}

.lesson-element__mnemonics {
  font-size: 16px;
  color: #333333;
  background-color: #eaf4fc;
  padding: 20px;
  border-radius: 5px;
  line-height: 1.5;
  height: 200px; /* Fixed height to ensure consistent layout */
  overflow-y: auto; /* Scroll if the content exceeds the height */
  margin-top: 15px;
  white-space: pre-wrap;
  display: flex;
  align-items: center; /* Vertically center the text */
  justify-content: center; /* Horizontally center the text */
}

.lesson-element__navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.lesson-element__button {
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.lesson-element__button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.lesson-element__button--prev {
  background-color: #f5a623;
}

.lesson-element__button--prev:hover:not(:disabled),
.lesson-element__button--next:hover:not(:disabled) {
  background-color: #3a70c2;
}

.lesson-element__button--next:hover:not(:disabled) {
  background-color: #388e3c;
}

.mnemonic_label {
  text-align: left;
  margin-bottom: -5px;
  margin-top: 20px;
}

.compose-lessons__quiz {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #cbbae9;
  padding: 20px;
  box-sizing: border-box;
}
