/* Dashboard.css */
.dashboard {
  padding: 30px 20px 20px 20px;
}

.dashboard__container {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  margin-top: 60px;
}

.dashboard__greeting {
  margin-top: 10px;
  margin-bottom: 25px;
}

.dashboard__row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .dashboard__row {
    flex-direction: column;
  }

  .dashboard__row > * {
    width: 100%;
  }
}
