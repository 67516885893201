/* RegisteredComingSoon.css */

.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f72585, #ff9f1c);
  font-family: 'Arial', sans-serif;
  color: #333;
  text-align: center;
  padding: 20px;
}

.coming-soon__content {
  max-width: 700px;
  padding: 40px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.coming-soon__title {
  font-size: 3em;
  margin-bottom: 20px;
  color: #00796b;
  font-weight: bold;
}

.coming-soon__text {
  font-size: 1.2em;
  margin: 15px 0;
  color: #555;
  line-height: 1.6;
}

.coming-soon__subtext {
  font-size: 1em;
  margin-top: 10px;
  color: #888;
}

.coming-soon__countdown {
  margin: 30px 0;
}

.coming-soon__countdown-title {
  font-size: 2em;
  margin-bottom: 15px;
  color: #00796b;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.coming-soon__countdown-timer {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  font-family: 'Courier New', Courier, monospace;
}

.coming-soon__countdown-item {
  background: #333;
  color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 1.5em;
  position: relative;
  transition: transform 0.3s ease;
}

.coming-soon__countdown-number {
  display: block;
  font-size: 3em;
  font-weight: bold;
}

.coming-soon__countdown-label {
  display: block;
  font-size: 0.9em;
}

.coming-soon__countdown-item:hover {
  transform: scale(1.1);
}

.coming-soon__subtext {
  font-size: 1em;
  margin-top: 20px;
  color: #888;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .coming-soon__content {
    padding: 20px;
  }

  .coming-soon__title {
    font-size: 2em;
  }

  .coming-soon__text {
    font-size: 1em;
  }

  .coming-soon__countdown-title {
    font-size: 1.5em;
  }

  .coming-soon__countdown-item {
    padding: 10px;
  }

  .coming-soon__countdown-number {
    font-size: 1.5em;
  }

  .coming-soon__countdown-label {
    font-size: 0.45em;
  }

  .coming-soon__countdown-item:hover {
    transform: none;
  }
}
