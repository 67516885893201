.quiz-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 450px;
  margin-bottom: 20px;
}

.quiz-card__container {
  padding: 20px;
  width: 100%;
}

.quiz-card__symbol {
  font-size: 60px;
  font-weight: bold;
  color: var(--blue-color);
  margin-bottom: 10px;
}

.quiz-card__input input {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  margin-bottom: 15px;
}

.quiz-card__button {
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: var(--blue-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.quiz-card__button:hover {
  background-color: var(--green-color);
}

.quiz-card__feedback {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}

.quiz-card__next-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: var(--blue-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.quiz-card__next-button:hover {
  background-color: var(--green-color);
}

.flip-card {
  perspective: 1000px;
  display: inline-block;
}

.flip-card-inner {
  position: relative;
  width: 280px;
  height: 50px;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg); /* Rotates the card on click */
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-front {
  background-color: #f0f0f0;
  color: black;
}

.flip-card-back {
  background-color: var(--lighter-orange-color);
  color: white;
  transform: rotateY(180deg);
}
