.header__logged-out {
  justify-content: space-between;
  padding: 5px 80px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: black;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.header__logged-out-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.header__logged-out-logo {
  display: flex;
  padding: 10px 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  color: var(--orange-color);
}

.header__logged-out-logo:hover {
  cursor: pointer;
}

.header__logged-out-logo-image {
  height: 35px;
  width: 35px;
  margin-right: 2px;
}

.header__logged-out-nav {
  display: flex;
  align-items: center;
}

.header__logged-out-link {
  margin: 0 15px;
  text-decoration: none;
  color: #fff;
}

.header__logged-out-button {
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header__logged-out {
    padding: 5px 20px;
  }

  .header__logged-out-link {
    display: none;
  }

  .header__logged-out-button {
    padding: 10px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 5px;
  }
}
