/* AboutQilin.css */

.about-qilin {
  background-color: #f9f9f9;
  padding: 20px 20px;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.container {
  max-width: 1160px;
  margin: auto auto;
}

.about-qilin__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-qilin__title {
  font-size: 2.5em;
  color: #333;
  text-align: center;
  margin: 50px;
}

.about-qilin__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  justify-content: center; /* Center the grid */
  align-items: start;
  width: 100%;
}

.about-qilin__card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 520px; /* Adjusted height */
  max-width: 360px; /* Adjusted width */
  min-width: 340px;
  transition: transform 0.2s;
  text-align: center;
  margin: 5px;
}

.about-qilin__card:hover {
  transform: scale(1.1);
}

.about-qilin__card-title {
  font-size: 2em;
  color: #444;
  margin-bottom: 10px;
  font-weight: 600;
}

.about-qilin__card-text {
  font-size: 1em;
  color: #666;
}

.about-qilin__card-icon {
  height: 90%; /* Adjust size as needed */
  margin-bottom: 20px;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .about-qilin {
    padding: 0px 10px;
  }

  .about-qilin__title {
    font-size: 1.8em;
    text-align: left;
    margin: 30px 10px 30px 10px;
    font-weight: 500;
  }

  .about-qilin__cards {
    grid-template-columns: 1fr;
    justify-items: center; /* Ensure items are centered on smaller screens */
  }

  .about-qilin__card {
    width: 100%;
    height: auto; /* Adjust height for mobile view */
  }

  .about-qilin__card:hover {
    transform: none;
  }

  .about-qilin__card-title {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .about-qilin__title {
    font-size: 1.5em;
    text-align: left;
    margin: 30px 10px 25px 10px;
    font-weight: 500;
  }
}
