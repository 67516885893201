.header__dropdown-profile {
  position: relative;
  display: inline-block;
}

.header__profile {
  border-radius: 50%;
  border: 1px solid var(--grey-color);
  margin-left: 15px;
  cursor: pointer;
}

.header__profile-picture {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.header__dropdown-profile-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  white-space: nowrap;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
}

.header__dropdown-profile:hover .header__dropdown-profile-menu {
  display: flex;
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%) translateY(0);
}

.header__dropdown-profile-menu::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 79%;
  transform: translateX(-50%);
  width: 20px;
  height: 10px;
  background-color: #fff;
  border-radius: 15px 10px 0 0;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  z-index: 999;
}

.header__dropdown-profile-button {
  background-color: #f8f9fa;
  color: #333;
  border: none;
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  margin-top: 10px; /* Adds space between the buttons */
}

.header__dropdown-profile-button:first-child {
  margin-top: 0; /* Ensures no extra margin above the first button */
}

.header__dropdown-profile-button:hover {
  background-color: #004e98;
  color: #fff;
}


