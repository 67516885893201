/* CourseSection.css */

.course_section {
  text-align: center;
  padding: 100px 20px;
  background-color: #f9f9f9;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.course_section__title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.course_section__text {
  font-size: 1.2em;
  margin: 10px 0;
  color: #555;
}

.course_section__button {
  padding: 15px 30px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.course_section__button:hover {
  background-color: #444;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .course_section {
    padding: 50px 20px;
  }

  .course_section__title {
    font-size: 2em;
    margin: 30px 10px 30px 10px;
    font-weight: 500;
  }
}
