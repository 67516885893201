/* LessonCard.css */
.lesson-card {
  background-color: var(--blue-color);
  border-radius: 8px;
  padding: 20px;
  color: white;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 320px;
}

.lesson-card__header {
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.lesson-card__title {
  font-size: 1.8rem;
  font-weight: bolder;
  margin-bottom: 5px;
}

.lesson-card__count {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 5px 10px;
  font-weight: bold;
  margin-left: 10px;
  font-size: 0.9rem;
}

/* .lesson-card__status {
  background-color: rgba(255, 0, 0);
  border-radius: 50%;
  padding: 5px 10px;
  font-weight: bold;
  margin-left: 5px;
  font-size: 0.8rem;
} */

.lesson-card__description {
  margin-bottom: 20px;
}

.lesson-card__button {
  background-color: white;
  color: var(--blue-color);
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.lesson-card__button:hover {
  transform: scale(1.02);
}

.lesson-card__image {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  /* background-image: url('/path-to-lesson-image.png'); */
  background-size: contain;
  background-repeat: no-repeat;
}
