body {
  margin: 0;
  font-family:
    'Helvetica',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Helvetica', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --pink-color: #eb66ac;
  --blue-color: #4a90e2;
  --orange-color: #f07e21;
  --lighter-orange-color: #ef8e34;
  --yellow-color: #eacf47;
  --green-color: #2ecc71;
  --red-color: #e6453c;
  --teal-color: #4fbcc4;
  --white-color: #fff;
  --off-white-color: #f5f5f5;
  --lighter-grey-color: #ddd;
  --light-grey-color: #ccc;
  --grey-color: #a5a5a5;
  --text-color: #333;
  --purple-color: #9877d5;
  --background-color: #f5f2ee;
  --dark-blue-color: #004e98;
}
