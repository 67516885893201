.header__dropdown {
  position: relative;
  display: inline-block;
}

.header__dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white-color);
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  white-space: nowrap;
  transition:
    opacity 0.3s ease,
    transform 5s ease;
  opacity: 0;
  pointer-events: none;
}

.header__dropdown:hover .header__dropdown-menu {
  display: block;
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%) translateY(0);
}

.dropdown-section {
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
  font-size: 14px;
}

.dropdown-section:last-child {
  margin-bottom: 0;
}

.levels-dropdown .dropdown-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
}

.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

.dropdown-label {
  font-weight: bold;
  margin-right: 10px;
}

.header__dropdown-menu a {
  display: inline-block;
  padding: 8px 10px;
  color: #333;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 500;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.header__dropdown-menu a {
  background-color: var(--off-white-color);
}

.header__dropdown-menu a:hover {
  background-color: var(--dark-blue-color);
  color: #fff;
}
/* Triangle at the top of the dropdown menu */
.header__dropdown-menu::before {
  content: '';
  position: absolute;
  top: -8px; /* Position the triangle at the top of the dropdown */
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 10px;
  background-color: #fff;
  border-radius: 15px 10px 0 0; /* Rounding the top corners */
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  z-index: 999; /* Ensure the triangle is on top of the dropdown */
}
